<template>
    <div id="apply-job-list">
        <van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
            <div class="content">
                <div class="empty" v-if="!isRefresh && !dataList.length">-- 已經全部加載 --</div>
                <router-link :to="`/job/details/${item.jobId}`" class="job-item" v-for="(item, index) in dataList" :key="index">
                   <div class="row-title">
                      <h1 class="title" v-text="item.jobInfo.title"></h1>
                   </div>
                   <div class="clinic-info">
                      <img :src="item.jobInfo.clinicLogo" :alt="item.jobInfo.clinicName" class="logo">
                      <h2 class="name" v-text="item.jobInfo.clinicName"></h2>
                   </div>
                   <div class="other-infos">
                      <div class="row">
                         <span class="hint">投遞時間：</span>
                         <div class="value" v-text="item.createTime"></div>
                      </div>
                      <div class="row">
                         <span class="hint">是否查閱：</span>
                         <div class="value" v-text="item.lookOver == true ? '是':'否'"></div>
                      </div>
                   </div>
 
               </router-link>
            </div>
        </van-pull-refresh>
    </div>
 </template>
<script>
   import apply_job from '@/views/mixins/apply_job'
   import Loading from '@/layouts/Loading.vue'
   import SplitPage from '@/layouts/SplitPage.vue'
   export default {
      mixins: [
         apply_job
      ],
      components: {
         Loading,
         SplitPage
      },
      data(){
         return {
            visible: false,
            loading: true,
            pageSize: 10,
            pageNo: 1,
            total: 0,
            dataList: [],
         }
      },
      mounted(){
         var hash = this.$route.hash
         hash = hash.replace('#', '')
         var list = hash.split('&')
         var key = list[0].split('=')[0]
         var value = list[0].split('=')[1] / 1
         if(key == 'page') {
            if(! isNaN(value)) {
                  this.pageNo = parseInt(value) < 1 ? 1 : parseInt(value)
            }
         }
         this.onRefresh()
      },
      methods: {
         onPageChanged(pageNo){
            var path = this.$route.fullPath
            var hash = this.$route.hash
            if(hash && path.indexOf(hash) != -1) {
                  path = path.replace(hash, '')
            }
            this.$router.push({path:  path+'#page='+pageNo},(e)=>{});
         },
         onRefresh(){
            if(this.total > 0 && this.pageNo > 0) {
                  var maxPage = Math.floor((this.total + this.pageSize - 1) / this.pageSize)
                  if(maxPage > 0 && maxPage < this.pageNo) {
                     var path = this.$route.fullPath
                     var hash = this.$route.hash
                     if(hash && path.indexOf(hash) != -1) {
                        path = path.replace(hash, '')
                     }
                     this.$router.push({path: `${path}#page=1`},(e)=>{})
                     return
                  }
            }
            this.loading = true
            const params = {
               "clientId": this.$store.getters.clientId,
               "pageNum": this.pageNo,
               "pageSize": this.pageSize,
               "uid": this.$store.getters.clientId
            }
            this.loadData(params, (data, error)=>{
               console.log(data,error)
                  this.loading = false
                  if(error) {
                     this._toast.warning(this, error)
                  } else {
                     this.dataList = data.list
                     this.total = data.total
                     this.visible = this.total > 0
                     var maxPage = Math.floor((this.total + this.pageSize - 1) / this.pageSize)
                     if(maxPage > 0 && maxPage < this.pageNo) {
                        var path = this.$route.fullPath
                        var hash = this.$route.hash
                        if(hash && path.indexOf(hash) != -1) {
                              path = path.replace(hash, '')
                        }
                        this.$router.push({path: `${path}#page=1`},(e)=>{})
                        return
                     }
                  }
            })
         },
      }
   }
</script>

<style lang="scss" scoped>
   #apply-job-list {
       width: 100%;
       display: flex;
       flex-direction: column;
   }
   #apply-job-list .content {
       flex: 1;
       width: 1000px;
       min-height: 500px;
       position: relative;
       margin: 0px auto;
   }

   #apply-job-list .content .empty {
       position: absolute;
       left: 50%;
       transform: translateX(-50%);
       top: 50px;
       z-index: 0;
       color: #9E9E9E;
       font-size: 24px;
   }

   #apply-job-list .job-item {
       position: relative;
       display: block;
       padding: 32px 26px;
       width: 100%;
       background-color: #fff;
       border-radius: 4px;
       margin-bottom: 12px;
   }

   #apply-job-list .job-item > .row-title {
       display: flex;
       flex-direction: row;
       line-height: 25px;
   }

   #apply-job-list .job-item > .row-title .title {
       color: #231F20;
       font-size: 24px;
       font-weight: bold;
       line-height: 24px;
   }

   #apply-job-list .job-item .job-intro {
       margin-top: 18px;
       display: flex;
       flex-direction: column;
   }

   #apply-job-list .job-item .job-intro .intro-title {
       color: #231F20;
       font-size: 14px;
       line-height: 14px;
   }

   #apply-job-list .job-item .job-intro .intro-content {
       color: #969696;
       font-size: 14px;
       margin-top: 8px;
   }

   #apply-job-list .job-item .job-intro:first-child {
       margin-bottom: 35px;
   }

   #apply-job-list .clinic-info {
       display: flex;
       flex-direction: row;
       align-items: center;
       margin-top: 10px;
   }

   #apply-job-list .clinic-info > img {
       display: inline-block;
       width: 40px;
       height: 40px;
       border-radius: 50%;
       padding: 4px;
       object-fit: contain;
       border: 1px solid #f3f3f3;
   }

   #apply-job-list .clinic-info > img[src=""] {
       position: relative;
   }

   #apply-job-list .clinic-info > img[src=""]::after {
       content: '';
       width: 40px;
       height: 40px;
       border-radius: 50%;
       background-color: #f1f1f1;
       position: absolute;
       left: 0;
       top: 0;
   }

   #apply-job-list .clinic-info > .name {
       color: #666;
       font-size: 14px;
       font-weight: bold;
       margin-left: 8px;
   }


#apply-job-list .clinic-info > .name {
    color: #666;
    font-size: 16px;
    font-weight: bold;
    margin-left: 8px;
}

#apply-job-list .other-infos {
   margin-top: 10px;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   font-size: 14px;
   font-weight: 400;
   line-height: 24px;
}

#apply-job-list .other-infos .row {
   display: flex;
   flex-direction: row;
}

   /** loading start */
   .lds-ring {
       display: inline-block;
       position: relative;
       width: 32px;
       height: 32px;
   }
   
   .lds-ring div {
       box-sizing: border-box;
       display: block;
       position: absolute;
       width: 24px;
       height: 24px;
       margin: 4px;
       border: 4px solid #36C4D0;
       border-radius: 50%;
       animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
       border-color: #36C4D0 transparent transparent transparent;
   }
   
   .lds-ring div:nth-child(1) {
       animation-delay: -0.45s;
   }
   
   .lds-ring div:nth-child(2) {
       animation-delay: -0.3s;
   }
   
   .lds-ring div:nth-child(3) {
       animation-delay: -0.15s;
   }

   @keyframes lds-ring {
       0% {
           transform: rotate(0deg);
       }
       100% {
           transform: rotate(360deg);
       }
   }
</style>
