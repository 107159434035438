export default {
    methods: {
        async loadData(data, callback) {
            try {
                var rs = await this.$store.dispatch('queryDeliveries', data)
                callback && callback({
                    list: rs.list,
                    total: rs.total,
                }, null)
            } catch (err){
                callback &&  callback(null, err)
            }
        }
    }
}